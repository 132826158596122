import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// projects import
import { CancelList, PickWaitList } from './components';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import MskPhone from 'components/masking/MskPhone';
import { PaymentInformation } from '../components';
import moment from 'moment';
import { InfoIcon } from 'components';

/**
 * 주문정보 탭 - 주문 상태가 픽업 대기(REQ_OK)인 경우 사용
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderTab({ order, products, history }) {
  // 승인된 상품 목록
  const [approvalProducts, setApprovalProducts] = React.useState([]);
  // 취소된 상품 목록
  const [cancelProduct, setCancelProducts] = React.useState([]);

  // 주문 결제 금액 관련 정보(총 주문 금액, 승인 금액, 취소 금액, 최종 매출 금액)
  const [paymentInfo, setPaymentInfo] = React.useState({
    productAmount: order?.pay_origin?.product ?? 0,
    reqOkAmount: 0,
    reqNoAmount: 0,
    resultAmount: 0
  });

  React.useEffect(() => {
    productProcess();
  }, []);

  // 상품목록 승인 내역/취소 내역 분기처리
  const productProcess = () => {
    const successList = []; // 승인 내역
    const failList = []; // 취소 내역

    // ---------------------------------------------- 승인/취소 내역 분기처리 [START]
    products.forEach((product) => {
      // origin_quantity가 없는 주문 건들 대응
      const customQuantity = product.origin_quantity ? product.origin_quantity : product.quantity;

      // 반품완료(RTN_OK) 일 경우
      if (product.state === 'RTN_OK') {
        failList.push({ ...product, quantity: 0 });
      }
      // 상품의 상태가 취소 완료(CANCEL, REQ_NO)
      else if (product.state === 'CANCEL' || product.state === 'REQ_NO') {
        failList.push(product);
      }
      // origin_quantity(최초 구매 병 수)와 quantity(실제 구매 병 수)가 다를 경우 (부분승인/부분취소)
      else if (customQuantity !== product.quantity) {
        successList.push(product);
        failList.push({ ...product, state: 'CANCEL' });
      } else if (
        product.state === 'REQ_OK' ||
        product.state === 'PICK_OK' ||
        product.state === 'CONFIRM' ||
        product.state === 'REQ_RTN' ||
        product.state === 'RTN_WAIT' ||
        product.state === 'RTN_NO' ||
        product.state === 'REQ_EXC' ||
        product.state === 'EXC_WAIT' ||
        product.state === 'EXC_OK' ||
        product.state === 'EXC_NO'
      ) {
        successList.push(product);
      }
    });

    setApprovalProducts(successList);
    setCancelProducts(failList);
    // ---------------------------------------------- 승인/취소 내역 분기처리 [END]

    // ---------------------------------------------- 결제정보 조회 [START]
    let reqOkAmount = 0; // 승인 금액
    let reqNoAmount = 0; // 취소 금액

    successList.forEach((product) => {
      reqOkAmount += product.price.original * product.quantity;
    });
    failList.forEach((product) => {
      reqNoAmount += product.price.original * (product.origin_quantity - product.quantity);
    });
    const resultAmount = order.pay_origin.product - reqNoAmount;
    setPaymentInfo({ ...paymentInfo, reqOkAmount, reqNoAmount, resultAmount });
    // ---------------------------------------------- 결제정보 조회 [END]
  };

  // 픽업시간 변경 이력 조회
  const historyDateFormat = (_date, _time) => {
    const date = moment(_date).format('YYYY년 MM월 DD일');
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(moment(date, 'YYYY년 MM월 DD일')).getDay()];
    const time = moment(_time, 'a hh:mm').format('a hh:mm');

    return `${date} (${dayOfWeek}) ${time}`;
  };

  // 픽업일시 데이터 포맷
  const dateFormat = React.useMemo(() => {
    if (!order) return;

    const { cobuy_state = 'NONE', promotion_id, promotion, pickup_date_time } = order;

    // 플랫폼 프로모션 주문 건
    if (promotion_id) {
      return `${moment(promotion.pickup.end_dt.toDate()).format('YYYY년 MM월 DD일 (ddd)')}까지`;
    }
    // 공동 구매 주문 건
    if (cobuy_state !== 'NONE') {
      return `${moment(pickup_date_time.toDate()).format('YYYY년 MM월 DD일 (ddd)')}까지`;
    }
    // 일반 주문 건
    return `${moment(pickup_date_time.toDate()).format('YYYY년 MM월 DD일 (ddd) a hh:mm')}`;
  }, [order]);

  // 구매한 유저 정보
  const userInformation = React.useMemo(() => {
    if (!order) return;

    const { state, user } = order;

    if (!state || !user) return null;

    // 마스킹 안된 전화번호 정규식
    const userPhoneNumber = user.phone.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');

    return (
      <Typography fontSize="14px" color="#2A2A2A">
        {/* 주문의 상태가 (승인요청, 승인)일 경우에는 마스킹 안된 연락처 Return */}
        {user.uname} / {state === 'REQ' || state === 'REQ_OK' ? userPhoneNumber : MskPhone(user.phone)}
      </Typography>
    );
  }, [order]);

  // 픽업 가이드 안내 문구
  const guideComponent = React.useMemo(() => {
    const { promotion_id } = order;

    // 프로모션 진행 중임에 따라 다른 문구를 렌더링함
    if (promotion_id) {
      const { state } = order.promotion;

      if (state !== 'FINISH') return <PromotionPickWaitGuide />;
    }
    return <PickupGuide />;
  }, [order]);

  // render
  return (
    <Box pb={12}>
      {/* 픽업일시 & 구매자 */}
      <Box padding="16px 20px">
        <Stack spacing={1}>
          <SpaceBetweenBox>
            <Typography fontSize="13px" color="#333333">
              픽업일시
            </Typography>
            <Box>
              {/* 픽업일시를 변경한 이력이 있을 시 */}
              {history.length > 0 &&
                history.map((h, index) => (
                  <Typography key={`pickup-history-${index}-row`} fontSize="14px" color="#FC735CE5" sx={{ textDecoration: 'line-through' }}>
                    {historyDateFormat(h.prev_pickup_date, h.prev_pickup_time)}
                  </Typography>
                ))}
              <Typography sx={{ fontSize: '14px', color: '#9357E5' }}>{dateFormat}</Typography>
            </Box>
          </SpaceBetweenBox>
          <SpaceBetweenBox>
            <Typography fontSize="13px" color="#333333">
              구매자
            </Typography>
            {userInformation}
          </SpaceBetweenBox>
        </Stack>
      </Box>

      <EmptyArea />

      <Box px="16px" mt="16px" mb="14px">
        {/* 픽업완료 안내문구 */}
        {guideComponent}
        {/*
        <PickupGuide />
        <PromotionPickWaitGuide />
        */}
      </Box>
      <Stack spacing={5} p="12px 20px">
        {/* 승인 내역 */}
        <PickWaitList order={order} products={approvalProducts} />

        {/* 취소 내역 */}
        {cancelProduct.length > 0 && <CancelList products={cancelProduct} />}
      </Stack>

      {/* 결제정보 */}
      <PaymentInformation order={order} paymentInfo={paymentInfo} />
    </Box>
  );
}

export default OrderTab;

OrderTab.propTypes = {
  order: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  history: PropTypes.array
};

const EmptyArea = styled('div')({
  width: '100%',
  height: '8px',
  backgroundColor: '#1303280A'
});

// 입고완료(픽업대기) 안내 문구
const PickupGuide = React.memo(() => {
  return (
    <Box sx={{ bgcolor: '#FBEFED', p: '12px 12px 16px' }}>
      <Stack direction="row" spacing="4px" alignItems="center">
        <InfoIcon width={15} height={18} color="#F46553" />
        <Typography sx={{ fontSize: 11, fontWeight: 'bold', letterSpacing: '-0.11px' }}>픽업 완료 처리 안내</Typography>
      </Stack>
      <Typography sx={{ mt: 1, fontSize: 11, letterSpacing: '-0.11px', wordBreak: 'keep-all' }}>
        픽업 고객의 <span style={{ color: '#F46C5B' }}>신분증을 확인</span>하고,{' '}
        <span style={{ color: '#F46C5B' }}>상품을 전달 하신 후 </span> 픽업 완료 처리를 진행해주세요.
      </Typography>
    </Box>
  );
});

// 플랫폼 프로모션 입고대기 안내 문구
const PromotionPickWaitGuide = React.memo(() => {
  return (
    <Box sx={{ bgcolor: 'rgba(60, 174, 71, 0.08)', p: '12px 12px 16px' }}>
      <Stack direction="row" spacing="4px" alignItems="center">
        <InfoIcon width={15} height={18} color="#3CAE47" />
        <Typography sx={{ fontSize: 11, fontWeight: 'bold', letterSpacing: '-0.11px' }}>상품 입고 안내</Typography>
      </Stack>
      <Typography sx={{ mt: 1, fontSize: 11, letterSpacing: '-0.11px', wordBreak: 'keep-all' }}>
        <span style={{ color: '#3CAE47' }}>샵으로 상품이 순차 배송될 예정입니다.</span> 입고 완료 후 고객이 샵 방문 시 픽업 완료 처리를
        진행해 주세요.
      </Typography>
    </Box>
  );
});
