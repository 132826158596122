import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// project imports
import { bottleCapacityToString } from 'config';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import MskPhone from 'components/masking/MskPhone';
import { OrderProductCheck } from '../../components';
import { InformationIcon } from 'components';
import OrderConfirm from '../step2';

/**
 * 주문정보 탭 - 주문 상태가 REQ(승인 대기)상태일 경우에만 사용
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderTab({ order, products }) {
  // 체크박스 상품 목록
  const [selectProducts, setSelectProducts] = React.useState();

  // 주문 승인 상품 목록 (중복o)
  const [reqOkProducts, setReqOkProducts] = React.useState([]);
  // 주문 거절 상품 목록 (중복o)
  const [reqNoProducts, setReqNoProducts] = React.useState([]);
  // 최종 주문 상품 목록
  const [resultProducts, setResultProducts] = React.useState([]);

  // 최종 주문 확인 화면 Drawer
  const [confirmDrawer, setConfirmDrawer] = React.useState({ error: false, open: false });

  const handleClose = () => {
    setConfirmDrawer({ ...confirmDrawer, open: false });
  };

  // 최종 주문 확인 Drawer 컴포넌트
  const orderConfirmDrawer = React.useMemo(() => {
    return (
      <OrderConfirm
        open={confirmDrawer.open}
        onClose={handleClose}
        order={order}
        reqOkProducts={reqOkProducts}
        reqNoProducts={reqNoProducts}
        resultProducts={resultProducts}
      />
    );
  }, [confirmDrawer.open]);

  // 픽업 시간
  const pickupDate = React.useMemo(() => {
    if (!order?.pickup_date_time) return;
    const formatData = order.pickup_date_time.toDate();
    return moment(formatData).format('YYYY년 MM월 DD일 (ddd) a hh:mm');
  }, [order?.pickup_date_time]);

  const selectEvent = (val) => {
    setSelectProducts(val);
  };

  const nextStep = () => {
    // products array type -> object type
    const productsObj = {};
    products.forEach((value) => {
      productsObj[value.id] = value;
    });

    const selectProductsObjKeys = Object.keys(selectProducts);
    const productsObjKeys = Object.keys(productsObj);

    // 승인상품목록
    const reqOkProductsArray = [];
    // 거절상품목록
    const reqNoProductsArray = [];

    // 승인상품목록, 거절상품목록 분류
    productsObjKeys.forEach((value) => {
      // 다음 화면에서 승인할 상품 목록 (중복o)
      if (selectProductsObjKeys.includes(value)) {
        reqOkProductsArray.push({ ...productsObj[value], ok_quantity: selectProducts[value].ok_quantity });
        if (selectProducts[value].quantity !== selectProducts[value].ok_quantity) {
          reqNoProductsArray.push({ ...productsObj[value], ok_quantity: selectProducts[value].ok_quantity });
        }
      }
      // 다음 화면에서 취소할 상품 목록 (중복o)
      else {
        reqNoProductsArray.push({ ...productsObj[value], ok_quantity: 0 });
      }
    });

    // 최종 상품 주문 목록
    const resultProductsArray = [];
    products.forEach((product) => {
      if (selectProducts[product.id] !== undefined) {
        resultProductsArray.push({
          id: product.id,
          product_id: product.product_id,
          quantity: product.quantity,
          ok_quantity: selectProducts[product.id].ok_quantity
        });
      } else {
        resultProductsArray.push({
          id: product.id,
          product_id: product.product_id,
          quantity: product.quantity,
          ok_quantity: 0
        });
      }
    });

    setReqOkProducts(reqOkProductsArray);
    setReqNoProducts(reqNoProductsArray);
    setResultProducts(resultProductsArray);

    setConfirmDrawer({ ...confirmDrawer, open: true });
  };

  // 구매한 유저 정보
  const userInformation = React.useMemo(() => {
    if (!order) return;

    const { state, user } = order;

    if (!state || !user) return null;

    // 마스킹 안된 전화번호 정규식
    const userPhoneNumber = user.phone.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');

    return (
      <Typography fontSize="14px" color="#2A2A2A">
        {/* 주문의 상태가 (승인요청, 승인)일 경우에는 마스킹 안된 연락처 Return */}
        {user.uname} / {state === 'REQ' || state === 'REQ_OK' ? userPhoneNumber : MskPhone(user.phone)}
      </Typography>
    );
  }, [order]);

  // 주문에 적용된 나라포스 캠페인이 있는지 여부
  const hasCampaigns = React.useMemo(() => order?.orderCampaigns?.length > 0, [order?.orderCampaigns]);

  // 주문에 적용된 나라포스 캠페인의 주문할인 금액
  const totalCampaignDiscountAmount = React.useMemo(() => {
    if ((order?.orderCampaigns?.length ?? 0) === 0) return 0;
    return order.orderCampaigns.reduce((acc, { discount }) => acc + discount.total, 0);
  }, [order?.orderCampaigns]);

  // render
  return (
    <>
      <Box padding="16px 0">
        {/* 주문내역 */}
        <Box px="20px">
          <Typography fontSize={14} fontWeight="bold" color="#333333">
            주문 내역 (총 {order?.pay?.quantity ?? '-'}병)
          </Typography>
          {/* 주문한 상품 목록 */}
          <ul style={{ marginTop: '12px', paddingLeft: '24px' }}>
            {products.map((product, index) => (
              <li key={`product-${index}-item`} style={{ marginBottom: '6px', fontSize: '14px', color: '#333333' }}>
                {product.name?.ko ?? '-'} / {product.vintage ?? '-'} / {bottleCapacityToString(product.capacity) ?? '-'} /{' '}
                {product.quantity ?? '-'}병
              </li>
            ))}
          </ul>
          <Divider />
        </Box>

        {/* 캠페인 할인내역 */}
        {hasCampaigns && (
          <Box pt={2} px="20px">
            <Typography fontSize={14} fontWeight="bold" color="#333333">
              적용된 캠페인 {order.orderCampaigns.length > 1 ? `${order.orderCampaigns.length}건` : null}
            </Typography>
            {/* 주문한 상품 목록 */}
            <ul style={{ marginTop: '12px', paddingLeft: '24px' }}>
              {order?.orderCampaigns.map(({ camp_nm, discount }, j) => {
                // console.log('---> orderCampaigns: ', camp_nm, discount);
                // render - order campaigns
                return (
                  <li key={`ord-camp-discnt-${j}-item`} style={{ marginBottom: '6px', fontSize: '14px', color: '#333333' }}>
                    <Typography fontSize={12} sx={{ wordBreak: 'keep-all' }}>
                      {camp_nm} - {discount.label} → {discount.total.toLocaleString()}원
                    </Typography>
                  </li>
                );
              })}
            </ul>
            <Divider />
          </Box>
        )}

        {/* 픽업정보 */}
        <Box px="20px" py={2}>
          <Stack spacing={1.5}>
            {/* 픽업일시 */}
            <SpaceBetweenBox>
              <Typography fontSize="13px" color="#333333">
                픽업일시
              </Typography>
              <Typography fontSize="14px" color="#2A2A2A">
                {pickupDate}
              </Typography>
            </SpaceBetweenBox>

            {/* 구매자 */}
            <SpaceBetweenBox>
              <Typography fontSize="13px" color="#333333">
                구매자
              </Typography>
              {userInformation}
            </SpaceBetweenBox>

            {/* 총 주문 금액 */}
            <SpaceBetweenBox>
              <Typography fontSize="13px" color="#333333">
                총 주문 금액
              </Typography>
              <Typography fontSize="14px" color="#2A2A2A">
                {order.pay_origin?.product?.toLocaleString() ?? '-'}원
              </Typography>
            </SpaceBetweenBox>

            {/* 캠페인 할인금액 */}
            {hasCampaigns && totalCampaignDiscountAmount > 0 && (
              <SpaceBetweenBox>
                <Typography fontSize="13px" color="#333333">
                  캠페인 할인
                </Typography>
                <Typography fontSize="14px" color="#2A2A2A">
                  -{totalCampaignDiscountAmount.toLocaleString()}원
                </Typography>
              </SpaceBetweenBox>
            )}
          </Stack>
        </Box>

        <EmptyArea />

        {/* 판매 불가 상품 선택 */}
        <Box px="20px" py={2}>
          <OrderProductCheck order={order} _products={products} selectEvent={selectEvent} />

          <Box px={2} mt="19px">
            <Typography fontSize={13} color="#FF6850" fontWeight="bold">
              승인 가능 상품이 없다면 체크를 모두 해지 후 다음 단계 버튼을 눌러주세요.
            </Typography>
          </Box>

          {/*
           * 캠페인 주문의 경우 전체 상품에 대해 승인 또는 거절만 가능함을 안내
           * - 캠페인 주문은 부분승인,거절을 할 수 없습니다
           */}
          {hasCampaigns && (
            <Box px={2} mt="19px">
              <Typography fontSize={13} color="#FF6850" fontWeight="bold">
                캠페인 주문의 경우 전체 승인(또는 거절)만 가능합니다.
              </Typography>
            </Box>
          )}

          <Box display="flex" mt={1.25}>
            <InformationIcon sx={{ color: '#555555', width: '13px', height: '13px', marginRight: '2px' }} />
            <Typography component="span" color="#33333399" fontSize="13px" marginLeft="6px">
              고객 정보를 상품판매 목적 외 사용, 보관, 공개 시 법적 처벌을 받을 수 있습니다.
            </Typography>
          </Box>

          {/* 다음단계 버튼 */}
          <Box mt="19px">
            <Button
              variant="contained"
              fullWidth
              sx={{ height: '50px', color: '#FFFFFF', fontSize: '14px', fontWeight: 'bold', borderRadius: '12px' }}
              onClick={() => nextStep()}
            >
              다음 단계
            </Button>
          </Box>
        </Box>
      </Box>

      {/* 최종 주문 확인 Drawer */}
      {orderConfirmDrawer}
    </>
  );
}

export default OrderTab;

OrderTab.propTypes = {
  order: PropTypes.object,
  products: PropTypes.array
};

const EmptyArea = styled('div')({
  width: '100%',
  height: '8px',
  backgroundColor: '#1303280A'
});
