import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// third-party: firebase
import { httpsCallable } from 'firebase/functions';
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'components/Loader';
import { removeFcmToken } from '../services/UserService';
import useMobileNative from '../hooks/useMobileNative';

import { getFirebaseFunctions } from '../utils/firebase-tools';
import { CLO_CODE } from '../config';
import axios from 'axios';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const AuthContext = createContext(null);

export const WineOneAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const auth = getAuth();
  const { onAuthChecked, getFcmToken } = useMobileNative();

  // firebase auth 초기화 여부
  const [firebaseAuthInitialized, setFirebaseAuthInitialized] = React.useState(false);

  // 최초
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      // detaching the listener
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const { claims } = idTokenResult;

        // 로그인 사용자의 입점샵 아이디
        user.vendorId = claims.vendor;

        // 나라포스 매장정보 확인
        const result = await axios
          .get(`${process.env.REACT_APP_API_SERVER_HOST}/util/vendor/${claims.vendor}/is-pos`)
          .then((res) => res.data)
          .catch((error) => {
            httpsCallable(
              getFirebaseFunctions(),
              'call-cdm-clo-error'
            )({
              code: CLO_CODE.UNEXPECTED_ERROR,
              title: `[Vendor APP][onAuthStateChanged] 입점샵[${claims?.vendor}] 정보 조회실패`,
              msg: `${JSON.stringify(error ?? {}, undefined, 2)}`,
              which: `vendor-app.onAuthStateChanged`,
              param: { error: error ?? {}, location: window.location.href, idTokenResult }
            })
              .then(console.log)
              .catch(console.error);

            throw error;
          });

        user.nara_pos_flag = result.nara_pos_flag ?? false;
        if (result.nara_pos_flag) {
          user.nara_pos_vendor_id = result.nara_pos_vendor_id;
        }

        // 개발환경에서 로그인 사용자 정보 확인
        if (process.env.NODE_ENV !== 'production') {
          console.log('[wineone] login user: ', user);
          console.log('[wineone] login access token : ', user.accessToken);
        }

        dispatch({ type: LOGIN, payload: { isLoggedIn: true, user } });
        setFirebaseAuthInitialized(true);
      } else {
        // No user is signed in...code to handle unauthenticated users.
        await logout();
        setFirebaseAuthInitialized(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  /**
   * 입점샵 로그인
   *
   * @param email 사용자 아이디 (이메일)
   * @param password 비밀번호
   * @param callback
   * @returns {Promise<void>}
   */
  const login = async (email, password, callback) => {
    console.debug(`[AuthContext][@login] 로그인 시작. email='${email}'`);
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password).catch((error) => ({ error }));

    // 로그인 요청 중 오류 발생
    if (userCredential.error) {
      const { error } = userCredential;
      console.warn(`[AuthContext] 입점샵 로그인 실패 'signInWithEmailAndPassword' error.`, error);
      callback(error);
      return undefined;
    }

    // Firebase authentication credential
    const { user: firebaseUser } = userCredential;

    // claims 검사
    const idTokenResult = await firebaseUser.getIdTokenResult().catch((error) => ({ error }));

    // 토큰정보 조회 실패
    if (idTokenResult.error) {
      const { error } = idTokenResult;
      console.error('[1KMWINE - VENDOR] 로그인 사용자 토큰조회 실패. ', error);
      callback(error);
      return undefined;
    }

    // Firebase user claims.
    const { claims } = idTokenResult;
    console.debug(`[AuthContext][@login] 로그인 시도한 사용자의 'claims' 체크.`, claims);

    // role이 입점샵(vendor)가 아닐 경우
    if (claims?.role !== 'vendor') {
      await logout(); // 로그아웃 처리
      // 입점샵 계정이 아닐 경우, 계정 추측을 막기 위해 로그인 사용자가 아니라는 token 처리
      console.warn(`[1KMWINE - VENDOR] 'vendor'권한을 가지지 않은 사용자의 로그인 시도.`);
      callback({ code: 'not-existing-customer' });
      return undefined;
    }

    await setPersistence(auth, browserLocalPersistence);
  }; // end of login

  const logout = async (callback) => {
    // FCM token 제거
    try {
      removeFcmToken();
    } catch (e) {
      console.error(e);
    }

    const logoutResult = await auth.signOut();
    console.log('@logout.result: ', logoutResult);
    dispatch({ type: LOGOUT });

    if (typeof callback === 'function') {
      callback();
    }
    return logoutResult;
  };

  /**
   * 로그인시 네이티브 호출
   */
  React.useEffect(() => {
    if (state.isInitialized && state.isLoggedIn) {
      onAuthChecked();
      getFcmToken();
    }
  }, [state.isInitialized, state.isLoggedIn]);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  if (!firebaseAuthInitialized) {
    return <Loader />;
  }

  return <AuthContext.Provider value={{ ...state, login, logout }}>{children}</AuthContext.Provider>;
};

WineOneAuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
