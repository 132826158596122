import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Divider, Typography } from '@mui/material';

// projects import
import SpaceBetweenBox from 'components/SpaceBetweenBox';

/**
 * 결제 정보 영역 (총 주문금액 / 승인 금액 / 취소 금액 / 최종 매출 금액)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PaymentInformation({ order, paymentInfo }) {
  // 주문에 적용된 나라포스 캠페인이 있는지 여부
  const hasCampaigns = React.useMemo(() => order?.orderCampaigns?.length > 0, [order?.orderCampaigns]);

  // 주문에 적용된 나라포스 캠페인의 주문할인 금액
  const totalCampaignDiscountAmount = React.useMemo(() => {
    if ((order?.orderCampaigns?.length ?? 0) === 0) return 0;
    return order.orderCampaigns.reduce((acc, { discount }) => acc + discount.total, 0);
  }, [order?.orderCampaigns]);

  // React.useLayoutEffect(() => {
  //   console.log('#-----> hasCampaigns: ', hasCampaigns);
  //   console.log('#-----> paymentInfo: ', paymentInfo);
  // }, [paymentInfo, hasCampaigns]);

  // render
  return (
    <Box px="20px">
      <Box border="1px solid #DCDCDC" borderRadius="10px">
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            총 주문 금액
          </Typography>
          <Typography fontSize="14px" color="#333333" fontWeight={800}>
            {paymentInfo.productAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            상품 승인 금액
          </Typography>
          <Typography fontSize="14px" color="#333333">
            {paymentInfo.reqOkAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />

        {hasCampaigns && totalCampaignDiscountAmount > 0 && (
          <>
            <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
              <Typography fontSize="14px" color="#333333" fontWeight="bold">
                캠페인 할인금액
              </Typography>
              <Typography fontSize="14px" color="#FC735C" fontWeight="bold">
                {totalCampaignDiscountAmount.toLocaleString()}원
              </Typography>
            </SpaceBetweenBox>
            <Divider />
          </>
        )}

        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            취소 금액
          </Typography>
          <Typography fontSize="14px" color="#FC735C" fontWeight="bold">
            {paymentInfo.reqNoAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            최종 매출 금액
          </Typography>
          <Typography fontSize="14px" color="primary" fontWeight="bold">
            {((paymentInfo?.resultAmount ?? 0) - totalCampaignDiscountAmount).toLocaleString()}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
      </Box>
    </Box>
  );
}

export default PaymentInformation;

PaymentInformation.propTypes = {
  order: PropTypes.object,
  paymentInfo: PropTypes.object.isRequired
};
