import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// projects import
import { ApprovalList, CancelList } from './components';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import MskPhone from 'components/masking/MskPhone';
import { PaymentInformation } from '../components';

// import mui
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * 주문정보 탭 - 주문 상태가 승인 대기(REQ)상태 또는 픽업 대기(REQ_OK)가 아닌 모든 상태에서 사용
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderTab({ order, products }) {
  // 승인된 상품 목록
  const [approvalProducts, setApprovalProducts] = React.useState([]);
  // 취소된 상품 목록
  const [cancelProduct, setCancelProducts] = React.useState([]);

  // 주문 결제 금액 관련 정보(총 주문 금액, 승인 금액, 취소 금액, 최종 매출 금액)
  const [paymentInfo, setPaymentInfo] = React.useState({
    productAmount: order?.pay_origin?.product ?? 0,
    reqOkAmount: 0,
    reqNoAmount: 0,
    resultAmount: 0
  });

  React.useEffect(() => {
    productProcess();
  }, []);

  // 상품목록 승인 내역/취소 내역 분기처리
  const productProcess = () => {
    const successList = []; // 승인 내역
    const failList = []; // 취소 내역

    // ---------------------------------------------- 승인/취소 내역 분기처리 [START]
    products.forEach((product) => {
      // origin_quantity가 없는 주문 건들 대응
      const customQuantity = product.origin_quantity ? product.origin_quantity : product.quantity;

      // 반품완료(RTN_OK) 일 경우
      if (product.state === 'RTN_OK') {
        failList.push({ ...product, quantity: 0 });
      }
      // 상품의 상태가 취소 완료(CANCEL, REQ_NO)
      else if (product.state === 'CANCEL' || product.state === 'REQ_NO') {
        failList.push(product);
      }
      // origin_quantity(최초 구매 병 수)와 quantity(실제 구매 병 수)가 다를 경우 (부분승인/부분취소)
      else if (customQuantity !== product.quantity) {
        successList.push(product);
        failList.push({ ...product, state: 'CANCEL' });
      } else if (
        product.state === 'REQ_OK' ||
        product.state === 'PICK_OK' ||
        product.state === 'CONFIRM' ||
        product.state === 'REQ_RTN' ||
        product.state === 'RTN_WAIT' ||
        product.state === 'RTN_NO' ||
        product.state === 'REQ_EXC' ||
        product.state === 'EXC_WAIT' ||
        product.state === 'EXC_OK' ||
        product.state === 'EXC_NO'
      ) {
        successList.push(product);
      }
    });

    setApprovalProducts(successList);
    setCancelProducts(failList);
    // ---------------------------------------------- 승인/취소 내역 분기처리 [END]

    // ---------------------------------------------- 결제정보 조회 [START]
    let reqOkAmount = 0; // 승인 금액
    let reqNoAmount = 0; // 취소 금액

    successList.forEach((product) => {
      reqOkAmount += product.price.original * product.quantity;
    });
    failList.forEach((product) => {
      reqNoAmount += product.price.original * (product.origin_quantity - product.quantity);
    });
    const resultAmount = order.pay_origin.product - reqNoAmount;
    setPaymentInfo({ ...paymentInfo, reqOkAmount, reqNoAmount, resultAmount });
    // ---------------------------------------------- 결제정보 조회 [END]
  };

  // 픽업일시 데이터 포맷
  const dateFormat = React.useMemo(() => {
    if (!order) return;

    const { cobuy_state = 'NONE', promotion_id, promotion, pickup_date_time } = order;

    // 플랫폼 프로모션 주문 건
    if (promotion_id) {
      return `${moment(promotion.pickup.end_dt.toDate()).format('YYYY년 MM월 DD일 (ddd)')}까지`;
    }
    // 공동 구매 주문 건
    if (cobuy_state !== 'NONE') {
      return `${moment(pickup_date_time.toDate()).format('YYYY년 MM월 DD일 (ddd)')}까지`;
    }
    // 일반 주문 건
    return `${moment(pickup_date_time.toDate()).format('YYYY년 MM월 DD일 (ddd) a hh:mm')}`;
  }, [order]);

  return (
    <Box pb={3}>
      {/* 픽업일시 & 구매자 */}
      <Box padding="16px 20px">
        <Stack spacing={1}>
          <SpaceBetweenBox>
            <Typography fontSize="13px" color="#333333">
              픽업일시
            </Typography>
            <Typography sx={{ fontSize: '14px', color: '#9357E5' }}>{dateFormat}</Typography>
          </SpaceBetweenBox>
          <SpaceBetweenBox>
            <Typography fontSize="13px" color="#333333">
              구매자
            </Typography>
            <Typography fontSize="14px" color="#2A2A2A">
              {order?.user?.uname}/{MskPhone(order?.user?.phone)}
            </Typography>
          </SpaceBetweenBox>
        </Stack>
      </Box>

      <EmptyArea />

      <Stack spacing={2} p="24px 20px">
        {/* 주문한 상품 목록 */}
        <ApprovalList order={order} products={approvalProducts} />

        {/* 거절한 상품 목록 */}
        <CancelList order={order} products={cancelProduct} />
      </Stack>

      {/* 매출 정보 */}
      <PaymentInformation order={order} paymentInfo={paymentInfo} />
    </Box>
  );
}

export default OrderTab;

OrderTab.propTypes = {
  order: PropTypes.object,
  products: PropTypes.array
};

const EmptyArea = styled('div')({
  width: '100%',
  height: '8px',
  backgroundColor: '#1303280A'
});
