import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { AppBar, Backdrop, Box, Skeleton, Stack, Toolbar, Typography } from '@mui/material';

// project imports
import { useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { ToolbarTitle } from 'components/toolbar';
import { WineBottleLoadingLottie } from 'components/spinner';
import ProductTab from './tab/ProductTab';
import OrderTab from '../order/tab/OtherState/OrderTab';
import PaymentTab from '../order/tab/components/PaymentTab';
import ReqRtnAction from './components/ReqRtnAction';
import RtnWaitAction from './components/RtnWaitAction';
import ReqExcAction from './components/ReqExcAction';
import ExcWaitAction from './components/ExcWaitAction';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { firebaseDB } from 'index';

// assets
import BackIcon from 'assets/images/arrow-back.png';
import useAuth from '../../../../hooks/useAuth';

/**
 * 주문 상품 상세 화면
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function DetailTab() {
  const scriptedRef = useScriptRef();
  const { user } = useAuth();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = React.useState(false);

  // 현재 선택한 탭
  const [selectTab, setSelectTab] = React.useState(0);

  // 주문상품 데이터 조회 성공 여부
  const [searchSuccess, setSearchSuccess] = React.useState(false);
  // 주문 정보
  const [order, setOrder] = React.useState(null);
  // 주문 상품 전체 정보
  const [products, setProducts] = React.useState([]);
  // 상품 정보
  const [product, setProduct] = React.useState(null);

  React.useEffect(() => {
    getOrderProductData();
    setSelectTab(0);
  }, [params]);

  // 주문 상품 상세 조회
  const getOrderProductData = async () => {
    const { oid, id } = params;
    console.log(`[주문상품상세] 주문번호(${oid})의 상품ID(${id}) 상세 조회 시작`);
    setSearchSuccess(false);
    setLoading(true);

    // 주문정보 조회
    const orderSnapshot = await getDoc(doc(firebaseDB, 'order', oid)).catch((error) => ({ error }));
    if (!scriptedRef.current) return;

    // 주문정보 조회 중 오류 발생
    if (orderSnapshot.error) {
      console.error(`[주문 상세] 주문정보(${oid}) 조회 중 오류 발생 > `, orderSnapshot.error);
      WoAlert.fire('주문 상세', '주문정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문정보가 올바르지 않음
    if (!orderSnapshot.exists()) {
      console.error(`[주문 상세] 주문정보(${oid})가 올바르지 않음`);
      WoAlert.fire('주문 상세', '주문정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문정보가 vendor와 맞지 않음
    if (user.vendorId !== orderSnapshot.data().vendor.id) {
      console.error(`[주문 상세] 다른 샵의 주문정보입니다`);
      WoAlert.fire('주문 상세', '다른 샵의 주문정보입니다', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문정보
    const orderData = orderSnapshot.data();

    /* 주문에 적용된 캠페인 정보 조회[S] */
    const orderCampaignDocsSnap = await getDocs(collection(orderSnapshot.ref, 'order_campaigns')).catch((error) => ({ error }));

    if (!scriptedRef.current) return;

    // 주문에 적용된 나라포스 캠페인 정보 조회 중 오류
    if (orderCampaignDocsSnap.error) {
      console.error(`[주문상세] 주문번호 '${oid}'의 주문정보의 캠페인 정보 조회 중 오류 발생 > `, orderCampaignDocsSnap.error);
      // window.alert('주문정보 조회 중 오류가 발생했습니다.\n\n잠시 후 다시 시도해주세요.');
      WoAlert.fire('주문 상세', '주문정보 조회 중 오류가 발생했습니다', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return;
    }

    // 주문에 적용된 캠페인 정보 세팅
    orderData.orderCampaigns = orderCampaignDocsSnap?.docs.map((doc) => doc.data()) ?? [];
    /* 주문에 적용된 캠페인 정보 조회[E] */

    setOrder(orderData);

    // 주문상품 조회
    const orderProductSnapshot = await getDocs(collection(firebaseDB, `order/${oid}/order_products`)).catch((error) => ({ error }));
    if (!scriptedRef.current) return;

    // 주문상품 조회 중 오류 발생
    if (orderProductSnapshot.error) {
      console.error(`[주문 상세] 주문번호(${oid})의 주문상품 목록 조회 중 오류 발생 > `, orderProductSnapshot.error);
      WoAlert.fire('주문 상세', '주문 상품정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    const { docs, empty, size } = orderProductSnapshot;

    // 주문상품이 비어있음(오류)
    if (empty || size === 0) {
      console.error(`[주문 상세] 주문번호(${oid})의 주문상품 목록 조회 중 오류 발생 > `, orderProductSnapshot.error);
      WoAlert.fire('주문 상세', '주문 상품정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문상품 임시배열
    const tempProductsArray = [];
    let selectProduct = null;
    for (let i = 0; i < size; i++) {
      if (docs[i].data().id === id) selectProduct = docs[i].data();
      tempProductsArray.push(docs[i].data());
    }
    setProducts(tempProductsArray);

    if (selectProduct === null || selectProduct === undefined) {
      console.error(`[주문 상세] 주문번호(${oid})의 주문상품 목록 조회 중 오류 발생 > `, orderProductSnapshot.error);
      WoAlert.fire('주문 상세', '주문 상품정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    setProduct(selectProduct);
    await setSearchSuccess(true);
    await setLoading(false);
  };

  // 주문상품 상태에 따른 헤더 라벨
  const stateToHeaderLabel = (state) => {
    let result;
    switch (state) {
      case 'REQ_RTN':
        result = '반품요청';
        break;
      case 'RTN_WAIT':
        result = '반품대기';
        break;
      case 'REQ_EXC':
        result = '교환요청';
        break;
      case 'EXC_WAIT':
        result = '교환대기';
        break;
      default:
        return '주문상품';
    }
    return result;
  };

  // 주문상품 상태에 따른 탭 라벨
  const stateToTabLabel = (state) => {
    let result;

    switch (state) {
      case 'REQ_RTN':
        result = '반품정보';
        break;
      case 'RTN_WAIT':
        result = '반품정보';
        break;
      case 'REQ_EXC':
        result = '교환정보';
        break;
      case 'EXC_WAIT':
        result = '교환정보';
        break;
      default:
        return '주문상품';
    }
    return result;
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">주문 정보를 불러오고 있습니다</Typography>
        </Box>
      </Backdrop>
      {/* HEADER */}
      <AppBar position="sticky">
        <Toolbar sx={{ padding: '10px 20px' }}>
          <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
          {searchSuccess ? (
            <ToolbarTitle>{stateToHeaderLabel(product.state)}</ToolbarTitle>
          ) : (
            <ToolbarTitle>
              <Skeleton variant="text" width={70} />
            </ToolbarTitle>
          )}
        </Toolbar>
      </AppBar>
      <Box position="relative" paddingBottom="90px">
        <Box padding="10px 20px">
          <Box width="100%" height="45px" bgcolor="#F3F2F4" borderRadius="10px" padding="5px">
            <Stack direction="row" spacing={1}>
              <Box
                width="100%"
                height="35px"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={selectTab === 0 && '#FFFFFF'}
                boxShadow={selectTab === 0 && '0px 1px 8px 1px #00000014'}
                onClick={() => setSelectTab(0)}
              >
                {searchSuccess ? (
                  <Typography component="span" fontSize="13px" color="#130328">
                    {stateToTabLabel(product.state)}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width={50} />
                )}
              </Box>
              <Box
                width="100%"
                height="35px"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={selectTab === 1 && '#FFFFFF'}
                boxShadow={selectTab === 1 && '0px 1px 8px 1px #00000014'}
                onClick={() => setSelectTab(1)}
              >
                <Typography component="span" fontSize="13px" color="#130328">
                  주문정보
                </Typography>
              </Box>
              <Box
                width="100%"
                height="35px"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={selectTab === 2 && '#FFFFFF'}
                boxShadow={selectTab === 2 && '0px 1px 8px 1px #00000014'}
                onClick={() => setSelectTab(2)}
              >
                <Typography component="span" fontSize="13px" color="#130328">
                  결제정보
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>

        {/* tab - 상품정보 */}
        {!loading && searchSuccess && selectTab === 0 && <ProductTab order={order} product={product} />}
        {/* tab - 주문정보 */}
        {!loading && searchSuccess && selectTab === 1 && <OrderTab order={order} products={products} />}
        {/* tab - 결제정보 */}
        {!loading && searchSuccess && selectTab === 2 && <PaymentTab order={order} products={products} />}

        {/* tab - 반품요청 */}
        {!loading && searchSuccess && product.state === 'REQ_RTN' && (
          <ReqRtnAction product={product} reloadOrder={() => getOrderProductData()} />
        )}
        {/* tab - 반품대기 */}
        {!loading && searchSuccess && product.state === 'RTN_WAIT' && (
          <RtnWaitAction product={product} reloadOrder={() => getOrderProductData()} />
        )}

        {/* tab - 교환요청 */}
        {!loading && searchSuccess && product.state === 'REQ_EXC' && (
          <ReqExcAction product={product} reloadOrder={() => getOrderProductData()} />
        )}
        {/* tab - 교환대기 */}
        {!loading && searchSuccess && product.state === 'EXC_WAIT' && (
          <ExcWaitAction product={product} reloadOrder={() => getOrderProductData()} />
        )}
      </Box>
    </>
  );
}

export default DetailTab;
